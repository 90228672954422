<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" lg="10">
          <v-card :disabled="isLoading" :loading="isLoading" class="shadow rounded-lg">

            <v-card-text class="pa-8">

              <v-row align="center" justify="center">
                <v-col cols="12" lg="6">

                  <v-card class="rounded-lg flex d-flex flex-column" outlined>
                    <v-card-title>
                      <div class="fs-16">
                        Couleur de l'arrière plan
                      </div>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="mb-0 pb-0 flex">
                      <v-color-picker v-model="setting.primary_color" hide-canvas hide-mode-switch mode="hexa"
                                      width="100%"/>
                    </v-card-text>
                  </v-card>

                  <v-card class="rounded-lg flex d-flex flex-column mt-5" outlined>
                    <v-card-title>
                      <div class="fs-16">
                        Image de l'arrière plan
                      </div>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="mb-0 pb-0 flex">
                      <v-layout align-center="" class="text-center" justify-center="">
                        <v-flex shrink>
                          <div class="profile-img">
                            <v-img
                                :src="setting.background_image ? setImage() : require('@/assets/no-image.png')"
                                class="rounded-circle"
                                height="90"
                                width="90">
                            </v-img>
                          </div>

                          <v-btn v-if="!setting.background_image"
                                 class="btn-upload"
                                 color="primary"
                                 dark
                                 depressed
                                 fab
                                 x-small
                                 @click="onButtonClick">
                            <v-icon>mdi-camera</v-icon>
                          </v-btn>

                          <v-btn v-if="setting.background_image"
                                 class="btn-upload"
                                 color="red"
                                 dark
                                 depressed
                                 fab
                                 x-small
                                 @click="setting.background_image = null">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>

                        </v-flex>
                      </v-layout>

                      <input ref="background_image"
                             accept="image/png,image/jpeg"
                             class="d-none"
                             type="file"
                             @change="uploadImage($event)">
                    </v-card-text>
                  </v-card>

                  <v-switch v-model="setting.under_maintenance"
                            hide-details
                            label="Application en maintenance"></v-switch>

                </v-col>
                <v-col class="text-center" cols="12" lg="6">
                  <img :src="require('@/assets/cog.svg')"
                       alt="cog"
                       width="250">
                </v-col>
              </v-row>


            </v-card-text>
          </v-card>

          <div class="text-end mt-3">
            <v-btn
                :disabled="isLoading"
                color="primary"
                depressed
                @click="saveSetting">
              Sauvegarder les modifications
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: false,
      isUpload: false,
      setting: {
        primary_color: "#20D489",
        background_image: "",
        under_maintenance: false
      },
    }
  },
  created() {
    this.getSetting()
  },
  methods: {
    setImage() {
      if (this.isUpload) {
        return this.setting.background_image
      } else {
        return this.$baseUrl + this.setting.background_image
      }
    },
    saveSetting() {
      this.isLoading = true
      HTTP.post('/setting/update', this.setting).then(() => {
        this.isLoading = false
        this.$successMessage = "Paramètre enregistré avec succès !"
      })
    },
    getSetting() {
      this.isLoading = true
      HTTP.get('/setting').then((res) => {
        this.isLoading = false
        this.setting = res && res.data && res.data.data ? res.data.data : {
          primary_color: "#20D489",
          background_image: "",
          under_maintenance: false
        }
      })
    },
    uploadImage(event) {
      let files = event.target.files
      let file = files[0]
      if (file) {
        const reader = new FileReader()
        reader.onloadend = () => {
          this.setting.background_image = reader.result
          this.isUpload = true
        }
        reader.readAsDataURL(file)
      }
    },
    onButtonClick() {
      window.addEventListener('focus', () => {
      }, {once: true})
      this.$refs.background_image.click()
    },
  }
}
</script>
<style scoped>

.profile-img {
  border: solid 1px #e9ebec;
  border-radius: 50%;
  padding: 5px;
}

.btn-upload {
  margin-right: -75px;
  margin-top: -50px
}
</style>